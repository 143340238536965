@import 'styles/utils';

.wrapper {
  padding: 70px 0;
  position: relative;
  display: flex;
  flex-direction: column;

  @include breakpoint('lg') {
    padding: 50px 0;
  }
}

.title.title {
  display: block;
  line-height: 2rem;
  @include breakpoint('lg') {
    line-height: 56px;
  }
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 20px;

  @include breakpoint('md') {
    flex-direction: row;
    align-items: center;
  }
  @include breakpoint('lg') {
    margin-bottom: 20px;
  }
}

.markdown p {
  line-height: 1.5rem;
  margin-bottom: 15px;
  @include breakpoint('lg') {
    margin-bottom: 20px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.bannerText.bannerText {
  & p {
    line-height: 18px;
    font-weight: 700;
    margin: 0;
    text-align: center;
  }
}
